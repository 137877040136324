import pdfMake from 'pdfmake';
import handlePurchase from "@/services/modules/purchase";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { toCapitalizeFirst } from '@/services/utils/global.js'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const piPdfPrint = () => {

    const {formatCurrency, currencyToWord} = handlePurchase();
    const exportToPDF = (company, invoice, barcode = '', qrcode, userName, btnFunc, invoiceNotes = []) => {
        const tableItems = invoice.has_item_detail ? invoice.general : invoice.ledgers;
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 60, 100, 60, 80 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(invoice, tableItems, barcode, btnFunc, invoiceNotes),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: invoice.bill_number
            }
        }

        pushIntoTable(doc, invoice, tableItems, btnFunc)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }
    const formatDate = (date) => {
        var formattedDate = date.toISOString().split('T')[0];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
        return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }
    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }
    const getHeader = (company) => {
        return {
            margin: [ 60, 25, 60, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60
                },

                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email,
                        'VAT Reg No: ' + company.vat_no,
                    ]
                }
            ]
        }
    }
    const getContent = (invoice, tableItems, barcode, btnFunc, invoiceNotes) => {
        const profileItems = invoice.contact ? invoice.contact.profile_items : [];
        const buyerName = invoice.buyer ? invoice.buyer.name : '-';

        return  [
            {
                text: (btnFunc == 1) ? 'Proforma Invoice (PI)' : 'DELIVERY CHALLAN',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                text: 'Customer Details',
                                color: 'black',
                                bold: true,
                            },
                            {
                                text: invoice.contact ? invoice.contact.name : '-',
                                alignment: 'left',
                                width: '50%'
                            },
                            {
                                text: getProfileItemByField(profileItems, 'address'),
                            },
                            {
                                text: getProfileItemByField(profileItems, 'billing_address'),
                            },
                            {
                                text: invoice.contact.address ?? ''
                            },
                            {
                                text: 'Buyer Name: ' + buyerName,
                                color: 'black',
                                bold: true,
                            },
                            {
                                text: 'Country Of Origin: ' + invoice.country ?? '-',
                                color: 'black',
                                bold: true,
                            },
                            {
                                text: 'Loading Port: ' + invoice.loading_port ?? '-',
                                color: 'black',
                                bold: true,
                            },
                            {
                                text: 'Delivery Port: ' + invoice.delivery_port ?? '-',
                                color: 'black',
                                bold: true,
                            },
                        ]
                    },

                    {
                        alignment: 'right',
                        stack: [
                            barcode ? {
                                text: [
                                    {
                                        text: (btnFunc == 1) ? 'INVOICE REF: ' : 'CHALLAN NO: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: invoice.bill_number ?? '-'
                                    }
                                 ]
                            } : '',

                            barcode ? {
                                svg: barcode
                            } : '',

                            (btnFunc == 1) ? '' : {
                                stack: [
                                    {
                                        text: invoice.location_id ? 'SHIP FROM:' : '',
                                        bold: true
                                    },
                                    {
                                        text: invoice.location_id ?? '-'
                                    }
                                ]
                            },
                            {
                                text: [
                                    {
                                        text: 'PI No: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: invoice.bill_number ?? '-',
                                    }
                                 ]
                            },
                            {
                                text: [
                                    {
                                        text: 'DATE: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: formatToDdMmYy(invoice.date ?? new Date()),
                                    }
                                 ]
                            },
                            {
                                text: [
                                    {
                                        text: 'Sales Contract Person: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: '-',
                                    }
                                 ]
                            },
                            {
                                text: [
                                    {
                                        text: 'Quotation Ref & date: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: invoice.order_number ?? ' ',
                                    }
                                 ]
                            },
                            {
                                text: [
                                    {
                                        text: 'Currency: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: invoice.currency ?? '-',
                                    }
                                 ]
                            },
                        ]
                    },
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: (btnFunc == 1) ? ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', '*'] : ['*', 'auto', 'auto', 'auto'],
                    body: []
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },

            {
                columns: [
                    {
                        width: '65%',
                        text: [
                            {
                                text: 'In Words: ',
                                bold: true
                            },
                            {
                                text: invoice.total_amount ? currencyToWord(invoice.total_amount) : ''
                            }
                        ]
                    },

                    (btnFunc == 1) ? {
                        table: {
                            widths: ['*', '*'],
                            alignment: 'right',
                            body: formatTotals(invoice)
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        }
                    } : ''
                ],
                margin: [0, 10]
            },

            {
                alignment: 'justify',
                text: [{ text: invoice.description ? 'TERMS & CONDITION: \n' : '', bold: true}, {text: invoice.description} ]
            },

            invoiceNotes.length ? {
                alignment: 'left',
                stack: formatInvoiceTerms(invoiceNotes)
            } : ''

        ]
    }

    const getFooter = (userName, qrcode) =>{
        return {
            margin: [ 60, 0, 60, 0 ],
            columns: [
                {
                    columns: [
                        {
                            svg: qrcode
                        },

                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    const getProfileItemByField = (profile_items, keyValue) => {
        const item = profile_items.find(item => item.field === keyValue);
        return (item) ? item.value : '';
    }

    const formatTotals = (invoice) => {
        const totals = [
            [{ text: 'Sales Amount' }, { text: formatCurrency(invoice.subtotal_amount), alignment: 'right'}],
            [ { text: 'VAT Amount' }, {text:  formatCurrency(invoice.vat_amount), alignment: 'right' } ],
            [ { text: 'Total Invoice Amount', bold: true }, { text: formatCurrency(invoice.total_amount), alignment: 'right'}],
        ]

        if(! invoice.paid_amount) return totals;

        return totals.concat([
            [ { text: 'Amount Paid', bold: true }, { text: formatCurrency(invoice.paid_amount), alignment: 'right'}],
            [ { text: 'Total Payable', bold: true }, { text: formatCurrency(invoice.total_amount - invoice.paid_amount), alignment: 'right'}],
        ]);
    }

    const formatInvoiceTerms = (terms) => {
        return  terms.map((item, index) => {
            return {
                text: `${item.full_desc}`
            }
        })
    }

    let srNumber = 0;
    const pushIntoTable = (doc, invoice, tableItems, btnFunc) => {
        srNumber = 0;
        var flag = false;
        tableItems.forEach(item => {
            if( !flag ){
                const tableHeader = getTableHeader(btnFunc);
                doc.content[2].table.body.push(tableHeader);
                flag = true;
            }
            const rowData = getRowData(invoice, item, btnFunc);
            return doc.content[2].table.body.push(rowData);
        });
    }
    const getTableHeader = (btnFunc) => {
        let headerInvoice = [
            { text: 'SL #' },
            { text: 'Style' },
            { text: 'PO Number' },
            { text: 'Item' },
            { text: 'Color' },
            { text: 'Size' },
            { text: 'UoM' },
            { text: 'Qty' },
            { text: 'Unit Price', alignment: 'right' },
            { text: 'Total Amount', alignment: 'right' }
        ]

        let headerChallan = [
            { text: 'PARTICULARS' },
            { text: 'UNIT', alignment: 'left' },
            { text: 'DELIVERY QTY', alignment: 'right' },
            { text: 'REMARKS' }
        ]

        return (btnFunc == 1) ? headerInvoice : headerChallan;
    }

    const getRowData = (invoice, item, btnFunc) => {
        srNumber += 1;

        let finaTotalAmount = item.total_amount

        if (!invoice.has_item_detail){
            finaTotalAmount = item.credit - item.discount_amount + item.vat_amount
        }

        const productNameValue = getSpecificationName(item, 0);
        const colorName = getSpecificationName(item, 1);
        const sizeName = getSpecificationName(item, 2);
        const unitNameValue = unitName(item);

        return (btnFunc == 1) ?
        [
            { text:  srNumber },
            { text: (invoice.has_item_detail ? item.style : '-') },
            { text: (invoice.has_item_detail ? item.po_number : '-') },
            { text: productNameValue },
            { text: colorName },
            { text: sizeName },
            { text: unitNameValue },
            { text:  invoice.has_item_detail ? formatCurrency(item.quantity) : '', alignment: 'right' },
            { text: invoice.has_item_detail ? formatCurrency(item.rate) : '', alignment: 'right' },
            { text: formatCurrency(item.total_amount), alignment: 'right' }
        ] :

        [
            { text: productNameValue + '\n' + (item.description ? item.description : '') },
            { text: item.product ? unitNameValue : '' },
            { text: formatCurrency(invoice.total_quantity), alignment: 'right' },
            { text: '' }
        ]
    }

    const getSpecificationName = (item, index) => {
        if (item && item.product && item.product.specifications && item.product.specifications[index] && item.product.specifications[index].specifiable && item.product.specifications[index].specifiable.name) {
            return item.product.specifications[index].specifiable.name;
        }
        return '-';
    }

    const unitName = (item) => {
        if (item && item.product && item.product.description && item.product.description.unit && item.product.description.unit.name) {
            return item.product.description.unit.name;
        }
        return item.head.name;
    }

    const productName = (item) => {
        if (item && item.product && item.product.name) {
            return item.product.name;
        }
        return item.head.name;
    }

    return {
        exportToPDF
    }
}

export default piPdfPrint;
